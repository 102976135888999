import 'core-js/full';
import 'regenerator-runtime/runtime';
import 'new-event-polyfill';
import 'classlist-polyfill';
import 'style-scoped';
import 'semantic-ui-css/semantic.min.css';
import * as React from 'react'
import ReactDOM from 'react-dom';
import axios from 'axios';
import { initializeIcons } from "@fluentui/react";
import { registerIcons } from '@fluentui/react/lib/Styling';

import { PMProgressIndicator } from "components/SimpleComponents/PMProgressIndicator";
import { FileCsvIcon } from 'components/Images/FileCsvIcon';

import { GlobalEventManager } from 'Utils/GlobalEvents';

interface Document {
    documentMode?: any
}

declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean
    }
}

if ((document as Document)?.documentMode) {
    const { RegExp } = window;
    try {
        new RegExp('a', 'u');
    } catch (err) {
        // @ts-ignore
        window.RegExp =
            (pattern: string, flags: string | undefined) => {
                if (flags?.includes('u') === true) {
                    // Ignore unicode flag in RegExp
                    flags = flags === 'u' ? undefined : flags.replace('u', '');
                    // Discard parts of the patterns used by exceljs that error out in non-unicode RegExps.
                    pattern = pattern.replace(/\uDC00-\uDBFF/g, '');
                    pattern = pattern.replace(/\uDC00-\uDB7F/g, '');

                    return new RegExp(pattern, flags);
                }
                return new RegExp(pattern, flags);
            };
        // @ts-ignore
        window.RegExp.prototype = RegExp.prototype;
    }
}

initializeIcons();

registerIcons({
    icons: { "CloudImport" : <FileCsvIcon />
    }
});

export const authenticatedAxios = axios.create({ withCredentials: true })
authenticatedAxios.interceptors.request.use(
    request => {
        return request
    },
    error => {
        return Promise.reject(error)
    }
)

authenticatedAxios.interceptors.response.use((response) => {
    return response
}, 
error => {
  if (error.response.status === 401) {
    GlobalEventManager.SessionExpired();
  }
  return Promise.reject(error);
});

const Entry = React.lazy(/* webpackChunkName: "entry" */() => import("./entry"));

export const Index: React.FC = () => {
    return (
        <React.Suspense fallback={<PMProgressIndicator />}>
            <Entry />
        </React.Suspense>
    )
}

ReactDOM.render(<Index />, document.getElementById("root"));